import * as common from './common.js';

const dxfTbody = $('table#dxf-list tbody');

$(function () {

    // init
    common.reloadRows(dxfTbody);

    $('#btn-save-send-api').on('click', function () {
        const newUrl = $('#send-api').val();
        const url = $(this).data('api');

        axios
            .put(url, {
                "url": newUrl,
            })
            .then((response) => {
                alert('API URL saved.');
                location.reload();
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
    });

    // upload button click event
    $('button#fileup').on('click', function () {
        const file_elm = $('#dxf-file');
        if (! file_elm[0].files || ! file_elm[0].files.length) {
            return;
        }
        const fd = new FormData();
        const url = dxfTbody.data('api');
        fd.append('file', file_elm[0].files[0]);
        axios
            .post(url, fd,
                {
                    'headers': {
                        'content-type': 'multipart/form-data',
                    },
                }
            )
            .then((response) => {
                file_elm.val('');
                common.reloadRows(dxfTbody);
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
    });

    // send button click event
    $(document).on('click', 'button.send', function () {
        const url = $('#send-api').val();
        const path = $(this).closest('tr').data('path');

        const request = {
            "dxfFilePath": path
        };

        axios
            .post(url, request,
                {
                    'headers': {
                        'content-type': 'application/json;charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                        'X-ZF-API-Token': process.env.MIX_ZF_API_Token
                    },
                })
            .then((response) => {
                const requestId = response.data.requestId;
                alert('ID: ' + requestId);
            })
            .catch((error) => {
                alert(error.resopnse.data.message);
            });
    });
});
